import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, ThemeContext } from 'grommet';
import { Tabs, Tab } from '../Tabs';

import { shuffle } from '../../lib/nodash';
import { getProductPath, isPaint, findMetafieldValue } from '../../lib/product';
import findImage from '../../lib/findImage';
import PaintCollectionCard from '../Paint/PaintCollectionCard';
import ProductCard from '../Product/ProductCard';
import DynamicGrid from '../DynamicPage/DynamicGrid';
import useMobile from '../useMobile';

const DynamicRelatedProductsTabs = ({
  blok: { limit = 4, type },
  // product,
  // collection,
  // relatedCollections,
  relatedPaintCollections,
  relatedProducts,
  relatedColorProducts,
}) => {
  const theme = useContext(ThemeContext);
  const isRugs = type === 'rugs';
  const isWallcoverings = type === 'wallcoverings';
  const relatedPaintProducts = relatedProducts.filter(isPaint);
  const paintColCount =
    relatedPaintProducts.length > 4 ? 4 : relatedPaintProducts.length;

  const backdropRelatedColorProducts = useMemo(
    () =>
      (relatedColorProducts || []).filter(
        (product) => product?.designer?.value === 'Backdrop'
      ),
    [relatedColorProducts]
  );
  const selectedRelatedColorProducts = useMemo(
    () =>
      isWallcoverings ? backdropRelatedColorProducts : relatedColorProducts,
    [isWallcoverings, backdropRelatedColorProducts, relatedColorProducts]
  );

  const randomColorProducts = useMemo(
    () => shuffle(selectedRelatedColorProducts).slice(0, parseInt(limit)),
    [selectedRelatedColorProducts, limit]
  );

  const patternColCount = isRugs
    ? 4
    : randomColorProducts.length > 4
    ? 4
    : randomColorProducts.length;
  const isMobile = useMobile();

  const youMayLike = (
    isWallcoverings ? randomColorProducts : relatedProducts
  ).slice(0, parseInt(limit));
  return (
    <Box fill="horizontal">
      <Tabs>
        {isWallcoverings ? (
          <Tab plain title="Paint Pairings">
            <DynamicGrid
              blok={{
                name: 'Related Products Tabs',
                columnCount: paintColCount,
                mobileColumnCount: 2,
                mobileHorizontalScroll: true,
                mobileHorizontalScrollColumnWidth: '60vw',
                rowsFillContainerHeight: true,
                gap: '5vw',
                items: relatedPaintProducts.map((x) => {
                  const paintCollection = relatedPaintCollections.find((c) => {
                    return (
                      findMetafieldValue('type', c.metafields) === 'Paint' &&
                      c.products.map((p) => p.shopifyId).indexOf(x.shopifyId) >
                        -1
                    );
                  });

                  return {
                    reactComponent: (
                      <Box key={x.id} align="center" margin="0 auto" fill>
                        <PaintCollectionCard
                          collection={paintCollection}
                          product={x}
                          title={x.title}
                          fill
                          showShortDescription={true}
                          imageContainerProps={{
                            width: { max: '250px' },
                            fill: 'horizontal',
                          }}
                          containerProps={{
                            pad: {
                              horizontal: 'xsmall',
                              vertical: '5vw',
                            },
                          }}
                          badgeContainerProps={{
                            pad: '1vw',
                          }}
                          srcSetSizes={`(max-width: ${theme.global.breakpoints.small.value}px) 100vw, (max-width: 800px) 70vw, (max-width: 1125px) 18vw, 12vw`}
                        />
                      </Box>
                    ),
                  };
                }),
              }}
            ></DynamicGrid>
          </Tab>
        ) : null}
        <Tab plain title="You May Also Like" width="100%">
          <Grid
            columns={{ count: isMobile ? 2 : patternColCount, size: 'auto' }}
            gap="small"
            pad="medium"
          >
            {youMayLike.map((x) => {
              return (
                <Box key={x.id}>
                  <ProductCard
                    collectionImageUrl={
                      x.productType === 'Rugs'
                        ? findImage(x.media, '_hero')
                        : findImage(x.media, '_Detail') ||
                          findImage(x.media, '_slide2') ||
                          x.media[0]?.image?.originalSrc
                    }
                    title={x.title}
                    showTitle={isRugs}
                    detailsPath={getProductPath(x)}
                    imageFit={isRugs ? 'contain' : 'cover'}
                    imageFill="contain"
                    pad="none"
                    sizes={`${100 / patternColCount}vw`}
                    imageSrcSetOptions={{
                      ar: '1:1',
                      crop: 'entropy',
                      fit: 'crop',
                    }}
                  />
                </Box>
              );
            })}
          </Grid>
        </Tab>
      </Tabs>
    </Box>
  );
};

DynamicRelatedProductsTabs.propTypes = {
  blok: PropTypes.shape({
    limit: PropTypes.number,
    type: PropTypes.oneOf(['wallcoverings', 'rugs']),
  }),
  collection: PropTypes.object,
  product: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  relatedPaintCollections: PropTypes.array,
  relatedColorProducts: PropTypes.array,
};

export default DynamicRelatedProductsTabs;
